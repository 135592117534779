/**
 * These are up to date as of July 25, 2023. Regenerate the list using this query:
 *   ```
       select type || ' = ''' || type || ''',' from building_suite_contract_conditions
       order by type asc
 *   ```
 */
export enum CONTRACT_CONDITION_TYPE {
  AUTO_EQUITY_PURCHASE = 'AUTO_EQUITY_PURCHASE',
  BETA = 'BETA',
  CARRYING_COST = 'CARRYING_COST',
  CONDO_FEES = 'CONDO_FEES',
  CONTRACTUAL_RENT = 'CONTRACTUAL_RENT',
  CO_FINANCING = 'CO_FINANCING',
  DOWNPAYMENT_FIXED = 'DOWNPAYMENT_FIXED',
  DOWNPAYMENT_PERCENTAGE = 'DOWNPAYMENT_PERCENTAGE',
  EQUITY_BOOST = 'EQUITY_BOOST',
  HAAS_FEE_FIXED = 'HAAS_FEE_FIXED',
  HAAS_FEE_PRORATED = 'HAAS_FEE_PRORATED',
  INTEREST_RATE = 'INTEREST_RATE',
  LIMITER_RATE = 'LIMITER_RATE',
  LOCKER = 'LOCKER',
  MAINTENANCE = 'MAINTENANCE',
  OWNERSHIP_SAVINGS_REINVESTMENT = 'OWNERSHIP_SAVINGS_REINVESTMENT',
  PARKING = 'PARKING',
  PROPERTY_TAX = 'PROPERTY_TAX',
  SUITE_INSURANCE = 'SUITE_INSURANCE',
  TENANT_RENT = 'TENANT_RENT',
  THIRD_PARTY_LOCKER = 'THIRD_PARTY_LOCKER',
  THIRD_PARTY_PARKING = 'THIRD_PARTY_PARKING',
  RESIDENT_SOURCING_FEE = 'RESIDENT_SOURCING_FEE',
  HOME_ONBOARDING_FEE = 'HOME_ONBOARDING_FEE',
  SALES_TAX = 'SALES_TAX',
  RESIDENT_ONBOARDING_FEE = 'RESIDENT_ONBOARDING_FEE',
  OCCUPANCY_TERM = 'OCCUPANCY_TERM',
  HOME_PRICE_INDEX_VALUATION_METHOD = 'HOME_PRICE_INDEX_VALUATION_METHOD',
  FIXED_GROWTH_VALUATION_METHOD = 'FIXED_GROWTH_VALUATION_METHOD',
  MINIMUM_GROSS_INCOME = 'MINIMUM_GROSS_INCOME',
}

export enum CONTRACT_CONDITION_FORMULA {
  PERCENTAGE = 'PERCENTAGE',
  FIXED = 'FIXED',
  BINARY = 'BINARY',
  YEAR = 'YEAR',
}

// This matches the structure in the DB
export interface BaseContractCondition {
  id: string;
  type: CONTRACT_CONDITION_TYPE;
  description: string | null;
  explanation: string | null;
  formula: CONTRACT_CONDITION_FORMULA;
  created_at: string | null;
  updated_at: string | null;
}

// This matches what comes from the GET route after DB joins/manipulation
export interface ContractCondition extends BaseContractCondition {
  created_at: string;
  updated_at: string;
}